/* UploadTestResult.css */

.table-container {
    max-height: 500px; /* You can set it to whatever you need */
    overflow: auto; 
  }
  
  .excelTable {
    font-size: 9px; /* Decrease font size */
  }
  
  .excelTable .ExcelTable2007 {
    width: 100%; /* Make the table full width of its container */
    table-layout: auto; /* Allows the table to size according to its content */
    word-break: break-all; /* To prevent overflow of content */
  }
  
  .excelTable .ExcelTable2007 td, .excelTable .ExcelTable2007 th {
    padding: 2px; /* Further decrease cell padding */
    line-height: 0.8; /* Decrease line height */
  }
  